





/* eslint-disable */ 
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


export default class App extends Vue {

 
}

