
















/* eslint-disable */ 
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class Header extends Vue {
    @Prop({
        type: Number, // 父组件传递给子组件的数据类型
        required: false, // 是否必填
        default: ' ' // 默认值， 如果传入的是 Object，则要 default: ()=>({}) 参数为函数
    })  index !: Number;

    // data
    private nav:string[] = ['首页','关于'];
    // methods
    public navColor(e:number):void{
        enum options {index,aboutUs};
        this.index = e
        console.log(this.$route)
        this.$router.push(`/${options[e]}`)
    }

    created () {
    }
}
