





















/* eslint-disable */ 
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface swiper{
    loop?:boolean;
    effect?: string;
    autoplay?:any;
    slidesPerView?: number;
    centeredSlides?: boolean;
    coverflowEffect?:any;
}

import Header from '@/components/index/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

@Component({
  components:{
    Header,
    Footer

  }
})
export default class Index extends Vue {

    // data
     swiperOptions:swiper= {
         loop : true,
         slidesPerView:3
     }
}

