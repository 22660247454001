

































interface swiper{
    loop?:boolean; 
    effect?: string;
    autoplay?:any;
    slidesPerView?: number;
    centeredSlides?: boolean;
    coverflowEffect?:any;
}

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/index/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

@Component({
  components:{
    Header,
    Footer

  }
})
export default class C extends Vue {
    private swiperOptions:swiper= {
         loop : true,
         slidesPerView: 3,
       
     }
    private activeName:string =  'first' 
    handleClick(tab:number, event:any) {
        console.log(tab, event);
    }
    
}
