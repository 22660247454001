import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import index from '@/views/index.vue'
import aboutUs from '@/views/aboutUs.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/index', component:index },
  { path: '/aboutUs', component:aboutUs },
  {
    path: '',
    redirect: '/index'
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
